import React, {useState, useCallback} from "react";
import classnames from "classnames";
import _isNil from "lodash.isnil";
import TextInput, { OnChangeFunc} from "./text-input";
import Checkbox from "./checkbox";

import {PropertyError} from "../../apollo/utils";

import "./forms.less";

export type ValidationResult = {message: string}
export type ValidationFunc = (value: unknown) => ValidationResult|undefined;
export interface Field {
  name: string;
  labelName: string;
  type: string;
  isRequired?: boolean;
  placeholder?: string;
  width?: string;
  description?: string | JSX.Element;
	validation?: ValidationFunc;
  onKeyDown?: (e: React.KeyboardEvent) => void;
	className?: string;
}

interface FormProps {
	fields: Field[];
	values: {
		[fieldName: string]: any
	};
	onSubmit: (formData: any)=>void;
	onUpdate: (formData: any) => void;
	submitText?: string;
	submitClass?: string;
	actionButtons?: JSX.Element;
	showNext?: (formState: any) => boolean
	propertyErrors: PropertyError[];
	enableSubmit?: (enable: boolean) => void;
}

export type ErrorsObject = {
	[fieldName: string]: string
};

interface BuildFieldsArgs {
	fields: Field[];
	values: {
		[fieldName: string]: any
	};
	handleChange: OnChangeFunc
	validate?: (f: Field) => void;
	errors: ErrorsObject
}

export function buildFields({ fields, values, validate, handleChange, errors }: BuildFieldsArgs) {
	return fields.map((field) => {
		const errorMessage = errors[field.name];
		let onBlur;

		if (validate && (field.validation || field.isRequired)) {
			onBlur = validate(field);
		}

		switch (field.type) {
			case "checkbox": {
				return (
					<Checkbox
						key={field.name}
						onChange={handleChange} name={field.name}
						checked={values[field.name]}
						labelName={field.labelName}
						description={field.description}
						className={field.className}
					/>
				);
			}
			case "text":
			default: {
				return (
					<TextInput
						key={field.name}
						{...field}
						value={values[field.name]}
						onChange={handleChange}
						onBlur={onBlur}
						errorMessage={errorMessage}
					/>
				)
			}
		}
	});
}

function isFilled(type, value) {
	switch (type) {
		case "number":
			return _isNil(value)
		default:
			return value === "" || _isNil(value)
	}
}

export function checkForErrors(errors: ErrorsObject): boolean {
	for (const field in errors) {
		if (errors[field]) return true;
	}

	return false;
}

export default function Form({ fields, values, submitText = "Submit", submitClass, actionButtons, onSubmit, onUpdate, enableSubmit}: FormProps) {
	const [errors, setErrors] = useState({});

	function validate(field) {
		return (event: React.FormEvent<HTMLInputElement>) => {
			console.log("validate: ", field.name)
			const validationFunc = field.validation;
			const value = event.currentTarget.value;

			const validation = validationFunc && validationFunc(value);

			if (validation && validation.message) {
				errors[field.name] = validation.message;
			}
			else {
				errors[field.name] = undefined;
			}

			console.log({
				isRequired: field.isRequired,
				value
			})

			if (field.isRequired && isFilled(field.type, value)) {
				errors[field.name] = `${field.labelName} is required`
			}

			setErrors(Object.assign({}, errors));
		}
	}

	function handleChange({ name, value }): void {
		onUpdate({ name, value });
	}

	const memoizedBuildFields = useCallback(buildFields, [fields, values, errors])

	const Fields = memoizedBuildFields({ fields, values, errors, validate, handleChange});

	const submitClasses = classnames("submit-button", {
		[submitClass as any]: !!submitClass,
	});

	const hasErrors = checkForErrors(errors);

	if (enableSubmit) {
		enableSubmit(!hasErrors);
	}

	return (
		<form onSubmit={(e) => { 
			if (!hasErrors) {
					onSubmit(e);
				}
			}
		}>
			{Fields}
			{actionButtons ? actionButtons : (
				<button className={submitClasses} type="submit">{submitText}</button>
			)}
		</form>
	)
}
