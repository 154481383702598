import React from 'react';
interface FooterHeaderProps {
  isCollapsed: boolean
}


export default function FooterHeader({isCollapsed} : FooterHeaderProps){
  return(
    <div className = "clearfix">
      <div className = "leftalign">
        SITEMAP
      </div>
      <div className = "rightalign">
      {isCollapsed ? "COLLAPSE" : "EXPAND"}
      </div>
    </div>

  )

}
