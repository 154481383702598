import * as React from "react";

interface AmbassadorProps {
	ambassador?: {
		name: string
		info: {
			logo: string,
			sentence: string,
			paragraph: string
		}
	}
}

export default function AmbassadorProfile({ambassador}: AmbassadorProps) {
	if (!ambassador) {
		return (
			<div>ERROR</div>
		);
	}


	return (
		<div className="container">
			<h1>{ambassador.name}</h1>
			<img src={ambassador.info.logo} />
			<h6>{ambassador.info.sentence}</h6>
			<p>
				{ambassador.info.paragraph}
			</p>
		</div>
	)
}
