import React, {useState} from "react";
import { render } from "react-dom";
import { ApolloProvider, useQuery } from '@apollo/client';
import Routes from "./Routes";
import gql from "graphql-tag";
import registerServiceWorker from "./registerServiceWorker";
import client from "./apollo";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext } from "./context";

import AppNav from "./components/app-nav";

import Footer from "./components/footer";
import MainContent from "./main-content";
import './less/index.less';


const GET_USER = gql`
	query GetCurrentUser {
		currentUser {
			id
			first_name
			last_name
			email
		}
	}
`;

function App() {
	return (
		<ApolloProvider client={client}>
			<AppContext />
		</ApolloProvider>
	);
}

function AppContext() {
	const { loading, data, refetch } = useQuery(GET_USER);
	const [navOpen, setNavOpen] = useState(true);

	const currentUser = data && data.currentUser;

	const authContextValue = {
		currentUser,
		refetch
	};

	return (
    <AuthContext.Provider value={authContextValue}>
      <Router>
				<AppNav navOpen={navOpen} setNavOpen={setNavOpen} user={currentUser} />
				<MainContent navOpen={navOpen}>
					<Routes user={currentUser} />
				</MainContent>
				<Footer navOpen={navOpen} />
      </Router>
    </AuthContext.Provider>
  );
}

render(
	<App />,
	document.getElementById('root') as HTMLElement
);
registerServiceWorker();
