const defaults = {
  currentUser: {
    __typename: "User",
    user: {}
  }
};

const resolvers = {
  Mutation: {
    setUser: (_: any, { user }, { cache, getCacheKey }): null => {
      const data = {
        currentUser: {
          __typename: "User",
          ...user
        }
      };

      const id = getCacheKey({ __typename: "User", id: user.id });

      cache.writeData({ id, data });

      return null;
    }
  }
};

const typeDefs = `
	type Query {
		currentUser: User
	}

	type User {
		first_name: string;
		last_name: string;
		email: string;
		id: ID;
	}
`;

export { defaults, resolvers, typeDefs };
