import * as React from "react"
import { ApolloError } from "@apollo/client";
import Form, {Field} from "../Forms";
import { Button, Icon } from "antd";
import classnames from "classnames";
import { findRelevantErrors } from "../../apollo/utils";
import Spinner from "../Spinner";

import "./step-form.less"

interface Step {
  name: string,
  title?: string,
  component?: any,
  noBack?: boolean,
  showNext?: (formState: unknown) => boolean,
  fields: Field[]
  className?: string;
}

interface StepFormProps {
  steps: Step[];
  [key: string]: any;
  onSubmit: (formData: unknown) => void;
  className?: string;
  errorsToCheck: string[];
  loading?: boolean;
}

interface StepFormState {
  index: number,
  prevIndex: number | null,
  shouldShowNext: boolean,
  stepFormData: {
    [stepName: string]: any
  },
  errors: {
    property: string,
    message: string
  }[],
  canSubmit: boolean
}

class StepForm extends React.PureComponent <StepFormProps, StepFormState> {
  constructor(props: StepFormProps) {
    super(props)

    this.state = {
      index: 0,
      prevIndex: null,
      shouldShowNext: !props.steps[0].showNext,
      stepFormData: this.props.steps.reduce((stepMap, step) => {
        stepMap[step.name] = {};

        return stepMap;
      }, {}),
      errors: [],
      canSubmit: false,
    }

    this.goNext    = this.goNext.bind(this)
    this.goBack    = this.goBack.bind(this)
    this.onEntered = this.onEntered.bind(this);
    this.showNext  = this.showNext.bind(this);
    this.onUpdate  = this.onUpdate.bind(this);
    this.onSubmit  = this.onSubmit.bind(this);
    this.updateCanSubmit = this.updateCanSubmit.bind(this);
  }


  goNext() {
    const maxIndex  = this.props.steps.length - 1
    const nextIndex = this.state.index + 1

    if (nextIndex <= maxIndex) {
      this.setState({
        index: nextIndex,
        shouldShowNext: !this.props.steps[nextIndex].showNext
      })
    }
  }

  goBack() {
    const backIndex = this.state.index - 1

    if (backIndex >= 0 && !this.props.steps[this.state.index].noBack) {
      this.setState({
        index: backIndex
      })
    }
  }

  getStep() {
    return this.props.steps[this.state.index]
  }

  getStepName() {
    return this.props.steps[this.state.index].name
  }

  onEntered() {
    this.setState({
      prevIndex: this.state.index
    })
  }

  showNext() {
    const Step = this.getStep();

    if (!Step.showNext) {
      return;
    }

    const formState = this.state.stepFormData[this.getStepName()];

    this.setState({ shouldShowNext: Step.showNext(formState) });
  }

  onUpdate({name, value}) {
    const stepName = this.getStepName();
    const stepFormState = this.state.stepFormData[stepName];
    stepFormState[name] = value;

    this.setState({
      stepFormData: Object.assign({}, this.state.stepFormData, {
        [stepName]: stepFormState
      })
    }, () => {
        this.showNext()
    })
  }

  updateCanSubmit(canSubmit: boolean) {
    this.setState({
      canSubmit
    })
  }

  async onSubmit() {
    if (!this.state.canSubmit) return false;

    const mergedData = Object.keys(this.state.stepFormData).reduce((mergedObj, stepName) => Object.assign(mergedObj, this.state.stepFormData[stepName]), {})

    try {
      await this.props.onSubmit(mergedData);
    }
    catch(error) {
      if (error.graphQLErrors) {
        const errors = findRelevantErrors(error.graphQLErrors, this.props.errorsToCheck);

        this.setState({errors})
      }
    }
  }

  render() {
    const isLastStep = this.state.index === this.props.steps.length - 1

    const Step = this.getStep()
    const canBack = !Step.noBack && this.state.index > 0
    const stepValues = this.state.stepFormData[Step.name]
    const stepCount = `${this.state.index + 1} of ${this.props.steps.length}`

    const stepClass = Step.className || "";

    const NextButton = this.state.shouldShowNext ? (
      <Button className="right" onClick={this.goNext}>
        Next
        <Icon type="right" />
      </Button>
    ) : null;
  
    const formClass = classnames("step-form", {
      [this.props.className as string]: !!this.props.className
    })

    const SubmitButton = this.props.loading ? (
      <Spinner />
    ) : (
      <Button type="primary" className="right" onClick={this.onSubmit}>
        Submit
      </Button>
    );

    const ActionButtons = (
      <div className="step-form__actions">
        {canBack ? (
          <Button icon="left" onClick={this.goBack}>
            Back
          </Button>
        ) : null}
        {isLastStep ? SubmitButton : NextButton}
      </div>
    )

    return (
      <div className={formClass}>
        <div className="step-form__header">
          {Step.title || Step.name}
          <span>Step {stepCount}</span>
        </div>
    <div className={`step-form__step ${stepClass}`}>
      <Form
        onSubmit={isLastStep ? this.onSubmit : this.goNext}
        actionButtons={ActionButtons}
        fields={Step.fields}
        propertyErrors={this.state.errors}
        values={stepValues}
        onUpdate={this.onUpdate}
        enableSubmit={this.updateCanSubmit}
      />
    </div>

        
      </div>
    );
  }
}

export default StepForm;
