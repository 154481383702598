import * as React from 'react';
import {
	Route,
	Switch,
} from "react-router-dom";
import PrivateRoute from "./private-route";
// import PublicOnlyRoute from "./public-only-route";
import Ambassador from "../components/Ambassador";
import Dashboard from "../components/User/Dashboard";
import Home from "../components/Home";
import Login from "../components/User/Login";
import Signup from "../components/User/Signup";
import NoMatch from "../components/NoMatch";
import Startup from "../components/Startup";
import Discover from "../components/discover";

interface RouteProps {
	user: {
		id?: string
	}
}

export function Routes(props: RouteProps) {
	const isAuthenticated = !!(props.user && props.user.id);

	return (
		<Switch>
			<Route exact path="/" component={Login} />
			<Route exact path="/login" component={Login} />
			<Route exact path={["/signup", "/register"]} component={Signup} />
			<PrivateRoute
				path="/dashboard"
				component={Dashboard}
				redirectTo="/login"
				isAuthenticated={isAuthenticated}
			/>

			<Route exact component={Startup} path="/company/:slug" />
			<Route exact component={Startup} path="/pre-company/:slug" />
			<Route exact component={Startup} path="/tech-transfer/:slug" />
			<Route exact component={Ambassador} path="/ambassador/:slug" />
			<Route exact component={Discover} path="/discover" />


			<Route component={NoMatch} />
		</Switch>
)}
export default Routes;
