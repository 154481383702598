import React from "react";
import classnames from "classnames";
import { Input } from "antd";

import "./text-input.css"

export type OnChangeFunc = (nameValue: NameValue) => void;

interface NameValue {
	name: string;
	value: string;
}
interface ITextInputProps {
	value?: string,
	default?: string,
	placeholder?: string,
	className?: string,
	labelClassName?: string,
	labelName?: string,
	name?: string,
	type?: string,
	width?: string,
	maxLength?: number,
	autoFocus?: boolean,
	disabled?: boolean,
	isViewable?: boolean,
	readOnly?: boolean,
	style?: any,
	isRequired?: boolean,
	hasError?: boolean,
	errorMessage?: string,
	hideLabel?: boolean,
	onChange: OnChangeFunc,
	onKeyDown?: any,
	onFocus?: any,
	onBlur?: any,
	onClick?: any,
}

const TextInput: React.SFC<ITextInputProps> = (props) => {

	const textClass = classnames("text-input", {
		[props.className as any]: !!props.className,
		"is-disabled": props.disabled && !props.isViewable,
		"text-input--error": props.hasError
	});

	const labelClass = classnames("text-input-label", {
		[props.labelClassName as any]: !!props.labelClassName,
		"hidden-label": props.hideLabel
	});

	const containerClass = classnames("text-input-container", {
		[props.width as any]: !!props.width
	});

	const labelText = props.isRequired ? `${props.labelName} *` : props.labelName;

	const Error = props.errorMessage ? (
		<div className="input-error">
			<span className="error-message">{props.errorMessage}</span>
		</div>
	) : null;

	function handleChange(e) {
		const {name, value} = e.target;

		props.onChange({name, value});
	}

	const InputComponent = props.type === "password" ? Input.Password : Input;

	return (
    <div className={containerClass}>
      <label className={labelClass} htmlFor={props.name}>
        {labelText}
      </label>
      <InputComponent
        className={textClass}
        name={props.name}
        type={props.type}
        defaultValue={props.default}
        value={props.value}
        readOnly={props.readOnly}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        onChange={handleChange}
        onKeyDown={props.onKeyDown}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onClick={props.onClick}
        style={props.style}
        maxLength={props.maxLength}
        aria-label={labelText}
      />
      {Error}
    </div>
  );
}

TextInput.defaultProps = {
	value: "",
	placeholder: "",
	type: "text",
	autoFocus: false,
	disabled: false,
	isViewable: true,
	readOnly: false,
	style: {},
	isRequired: false,
	hasError: false,
	errorMessage: "",
	hideLabel: false,
	onChange: () => { },
	onKeyDown: () => { },
	onFocus: () => { },
	onBlur: () => { },
	onClick: () => { },
};

export default TextInput;
