import React, { Fragment } from 'react';
import { Icon } from 'antd';

interface ColumnListItemProps {
  item: unknown;
  index: number;
}

interface Link {
  href: string;
  icon?: string;
  text: string;
}

interface TitledContent {
  title: string;
  content: string[];
}

function isItemLink(item: unknown): item is Link {
  return !!(item as Link).href;
}

function isTitleContent(item: unknown): item is TitledContent {
  return !!(item as TitledContent).title && Array.isArray((item as TitledContent).content);
}

export default function ColumnListItem({ item, index }: ColumnListItemProps) {
  // generic case
  let itemContent: string | JSX.Element = "";

  // Case where we have a link!
  if (isItemLink(item)) {
    itemContent = item.icon ? (<a key={index} href={item.href}><Icon type={item.icon} />{item.text}</a>) : (<a href={item.href}>{item.text}</a>)
  }

  // Case where have titled content!
  if (isTitleContent(item)) {
    itemContent = (
      <div>
        <h2>{item.title}</h2>
        {item.content.map((contentPiece) => (
          <p>{contentPiece}</p>
        ))}
      </div>
    );
  }

  return (
    <li key={index}>
      {itemContent}
    </li>
  )
}