import * as React from "react";
import QueryLoader from "../QueryLoader";
import AmbassadorProfile from "./profile";
import gql from "graphql-tag";

const GET_AMBASSADOR = gql`
	query GetAmbassador($slug: String!) {
		ambassador(slug: $slug) {
			id,
			name,
			organization_name,
			info {
				sentence,
				paragraph,
				logo
			}
		}
	}
`

interface AmbassadorProps {
	match: any
}

export default function Ambassador({match}: AmbassadorProps) {
	const slug = match.params.slug;

	return (
		<QueryLoader query={GET_AMBASSADOR} variables={{slug}} >
			<AmbassadorProfile />
		</QueryLoader>
	);
}
