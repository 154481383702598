import React, {ReactNode} from "react";
import { Layout } from "antd";
import classnames from "classnames";

const { Content } = Layout;


export default function MainContent({navOpen, children}: {navOpen: boolean, children: ReactNode}) {
  const classes = classnames("app-container", {
    "nav-open": navOpen 
  })

  return (
    <Layout className={classes}>
      <Content>
        {children}
      </Content>
    </Layout>
  )
}