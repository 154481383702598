import { GraphQLError } from "graphql";

export function extractErrorMessages(graphQLErrors: GraphQLError[]) {
  return graphQLErrors.map((error) => {
    if (error.message) {
      return error.message.replace("GraphQL error: ", "");
    }
  });
}

export interface PropertyError {
  property: string;
  message: string;
}

export function findRelevantErrors(
  graphQLErrors: readonly GraphQLError[],
  relevantErrors: string[]
) {
  return graphQLErrors.reduce((acc: PropertyError[], error) => {
    const code = error.extensions && error.extensions.code;

    if (code && relevantErrors.includes(code)) {
      const properties = error.extensions && error.extensions.properties;
      const message = error.message;

      properties.forEach((property) => {
        acc.push({
          property,
          message,
        });
      });
    }

    return acc;
  }, []);
}
