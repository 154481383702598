import * as React from 'react';
import QueryLoader from "../../QueryLoader";
import Dashboard from "./dashboard";
import gql from 'graphql-tag';

const GET_CURRENT_USER = gql`
	query GetCurrentUser {
		currentUser {
			id
			profiles {
				id,
				name,
				slug,
				type,
				created_at,
				published
			}
		}
	}
`;

export default function DashboardContainer() {
	return (
		<QueryLoader query={GET_CURRENT_USER}>
			<Dashboard currentUser= {{}}/>
		</QueryLoader>
	)
}
