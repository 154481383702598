import * as React from "react";
import QueryLoader from "../QueryLoader";
import StartupProfile from "./profile";
import gql from "graphql-tag";

const GET_STARTUP = gql`
	query GetStartup($slug: String!) {
		startup(slug: $slug) {
			id,
			name,
			type,
			info {
				sentence,
				paragraph,
				logo
			}
		}
	}
`

export default function Startup({match}) {
	const slug = match.params.slug;

	return (
		<QueryLoader query={GET_STARTUP} variables={{slug}} >
			<StartupProfile />
		</QueryLoader>
	)
}
