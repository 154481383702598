import * as React from "react";

export default function StartupProfile(props) {
	const startup = props.startup || {};

	return (
		<div className="container">
			<h1>{startup.name}</h1>
			<img src={startup.info.logo} />
			<h6>{startup.info.sentence}</h6>
			<p>
				{startup.info.paragraph}
			</p>
		</div>
	)
}
