import React, {useState} from 'react';
import { useHistory, Link } from "react-router-dom";
import { Input, Button, Checkbox } from "antd";
import {api_endpoint} from "../../../config";

import superagent from "superagent";
const agent = superagent.agent();

interface LoginFormProps {
	setUser: ({ variables: any }) => void,
	refetch: () => void;
}

export default function LoginForm({ setUser, refetch}: LoginFormProps) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(true);
	const history = useHistory();

	async function onSubmit(e: React.MouseEvent | React.FormEvent): Promise<void> {
		e.preventDefault();

		const loginRes = await agent.post(`${api_endpoint}/users/login`).withCredentials().send({
      email,
			password,
			rememberMe
    });

		const user = loginRes.body;

		if (user) {
			await refetch();

			history.push("/dashboard")
		}
	}

	return (
		<div>
			<div className="login-form">
				<form onSubmit={onSubmit}>
					<div className="grid-4x4">
						<Input autoFocus={true} name="email" className="full-width" type="email" placeholder="test@mailbox.com" value={email} onChange={(e) => { setEmail(e.target.value) }} />
						<Input name="password" className="full-width" type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
						<Checkbox style={{ fontSize: "12px" }} checked={rememberMe} onClick={(e) => setRememberMe(!rememberMe)}>Remember me</Checkbox>
						<Button htmlType="submit" type="primary" onClick={onSubmit}>Login</Button>
						<div className="form-cta">
							<span>Don't have an account?&nbsp;</span>
							<Link to="/signup">Sign Up Here!</Link>
						</div>
					</div>
				</form>
			</div>
		</div>
		
	)
}
