import React, {Fragment} from 'react';
import ColumnListItem from "./item";

interface ColumnListProps{
  header?: string;
  items: unknown[];
}

export default function ColumnList({header, items}: ColumnListProps){
  const ListItems = items.map(
    (item, index) => (<ColumnListItem item={item} index={index} />)
  );

  return(
    <Fragment >
      <div>
        {header ? (<h2> {header} </h2>) : null}
        <ul>
          {ListItems}
        </ul>
      </div>
    </Fragment>
  )
}
