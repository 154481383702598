import * as React from "react"
import "./spinner.css"

export default function Spinner() {
	return (
		<div className="spinner">
			<div className="cube1"></div>
			<div className="cube2"></div>
		</div>
	)
}
