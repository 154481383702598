import React from 'react';
import { Layout } from "antd";
import { Collapse } from 'antd';
import FooterHeader from './footer-header';
import FooterContent from './footer-content';
import { Icon } from 'antd';
import classnames from "classnames";
import "./footer.less";

const { Footer : AntdFooter } = Layout;


const { Panel } = Collapse;


export default function Footer({navOpen}){
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const Header = <FooterHeader isCollapsed={isCollapsed} />

  const classes = classnames("footer", {
    "nav-open": navOpen
  })

  function onChange() {
    setIsCollapsed(!isCollapsed);
  }
    return (
      <AntdFooter className={classes}>
        <Collapse bordered = {false} onChange={onChange}>
          <Panel header = {Header} key="1">
            <FooterContent />
          </Panel>
        </Collapse>
      </AntdFooter>
    )
}
