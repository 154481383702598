import React from 'react';

import ColumnList from '../columns/list';
import Logo from '../Images/logos/ar_logo_black.png';

export default function FooterContent(){
  return(
    <div>
      <div  className="flex-box">
        <ColumnList items={footerinformationlinks} header="Information" />
        <ColumnList items={footerplatformlinks} header="Platform" />
        <ColumnList items={footercustomerservicelinks} header="Customer Service" />
      </div>

      <div className = "footerlogo">
        <img src = {Logo} />
      </div>
      <div className="copyright">
        <p>© 2016 ANGELROOT, INC. ALL RIGHTS RESERVED.</p>
        <p>AngelRoot, Inc. is a New York State Benefit Corporation founded to empower entrepreneurs to cultivate big ideas and advance significant technologies.</p>
      </div>

    </div>

  )
}

const footerinformationlinks = [
  {
    href:"http://about.angelroot.com/",
    text:"General Information",
    icon:"info",
  },
  {
    href:"http://about.angelroot.com/angelroot---about.html",
    text:"About AngelRoot",
  },
  {
    href:"http://about.angelroot.com/angelroot---users.html",
    text:"Users Are",
    icon:"",
  },
  {
    href:"http://about.angelroot.com/angelroot---for-investors.html",
    text:"Investors Can",
    icon:"",
  },
  {
    href:"http://about.angelroot.com/angelroot---for-ambassadors.html",
    text:"Ambassadors Can",
    icon:"",
  },
  {
    href:"http://about.angelroot.com/angelroot---for-startups.html",
    text:"Startups Can",
    icon:"",
  },
  {
    href:"http://about.angelroot.com/angelroot---learn-more.html",
    text:"Learn More",
    icon:"",
  },
  {
    href:"http://about.angelroot.com/angelroot---how-to.html",
    text:"How To",
    icon:"",
  }

]



const footerplatformlinks = [
  {
    href:"/",
    text:" Home",
    icon:"home",
  },
  {
    href:"/discover",
    text:" Discover",
    icon:"compass",
  },
  {
    href:"/dashboard",
    text:" Dashboard",
    icon:"dashboard",
  },
  {
    href:"/",
    text:" Messages (Coming Soon)",
    icon:"mail",
  }
]

const footercustomerservicelinks = [
  {
    href:"/",
    text:"Contact AngelRoot",
    icon:"",
  },
  {
    href:"/",
    text:" Report a Bug",
    icon:"bug",
  },
  {
    href:"/faq",
    text:"FAQ",
    icon:"",
  },
  {
    href:"/tos",
    text:"Terms of Service",
    icon:"",
  },
  {
    href:"/privacy",
    text:"Privacy Policy",
    icon:"",
  },
  {
    href:"+1-347-725-1254",
    text:"+1-347-725-1254",
    icon:"phone",
  },
  {
    href:"mailto:info@angelroot.com",
    text:"info@angelroot.com",
    icon:"",
  }

]
