import React, { useContext } from "react";
import {useMutation} from "@apollo/client";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import { AuthContext } from "../../../context";

const LOGOUT_MUTATION = gql`
    mutation LogoutUser {
        logout
    }
`;

export default function Logout() {
    const [logoutUser, {client}] = useMutation(LOGOUT_MUTATION);
    const { refetch: refetchCurrentUser } = useContext(AuthContext);
    const history = useHistory();

    return (
        <button className="ant-btn logout" onClick={() => {
            logoutUser().then(async() => {
                await client?.clearStore();
                refetchCurrentUser();

                history.push("/");
            });
        }}>Logout</button>
    )
}