import * as React from 'react';
import ProfileList from "./profile-list";

export default function Dashboard({currentUser}) {
	if (!currentUser) return null;

	return (
		<div className="container">
			<h1>Dashboard for {currentUser.first_name}</h1>
			<div>{currentUser.email}</div>
			<ProfileList profiles={currentUser.profiles} />
		</div>
	);
}
