import React from "react";
import SignupForm from "./signup-form";

import { AuthContext } from "../../../context";

export default function Signup() {
  return (
    <AuthContext.Consumer>
      {({refetch}) => (
        <SignupForm refetch={refetch} />
      )}
    </AuthContext.Consumer>
  )
}
