import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { resolvers, typeDefs } from "./client-state";
import { api_endpoint } from "../config";

const link = new HttpLink({
  uri: `${api_endpoint}/graphql`,
  credentials: "include",
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  resolvers,
  typeDefs,
  link,
  cache,
});

const defaultData = {
  currentUser: {},
};

cache.writeData({
  data: defaultData,
});

client.onResetStore(() => {
  return new Promise((resolve) => {
    resolve(cache.writeData({ data: defaultData }));
  });
});

export default client;
