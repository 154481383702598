import React, {Fragment} from "react";
import { useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { Redirect } from "react-router-dom";

import {AuthContext} from "../../../context";

import LoginForm from "./login-form";

import "./login.less";

const SET_USER = gql`
	mutation SetUser($user: User) {
		setUser(user: $user) @client
	}
`;

export default function Login() {
	const [setUser] = useMutation(SET_USER);

	return (
			<AuthContext.Consumer>
				{
					(ctx) => {
						return (
						<Fragment>
							{
								ctx.currentUser ? (
									<Redirect to="/dashboard" />
								) : (
										<LoginForm setUser={setUser}
										refetch={ctx.refetch}
										/>
									)
							}
						</Fragment>
					)}
				}
			</AuthContext.Consumer>
	)
}
