import * as React from "react"
import {Redirect, Route} from "react-router-dom"

export default function PrivateRoute({component: Component, path, redirectTo, isAuthenticated, ...rest}) {
	return (
		<Route {...rest} path={path} render={(props) => isAuthenticated ? (
				<Component {...props} />
			) : (
				<Redirect to={redirectTo} />
			)
		}
		/>
	)
}
