import React from "react";
import Terms from "./terms";

export const personalInfoFields = [
  {
    name: "first_name",
    labelName: "First Name",
    type: "text",
    isRequired: true,
    placeholder: "First Name",
    width: "half"
  },
  {
    name: "last_name",
    labelName: "Last Name",
    type: "text",
    isRequired: true,
    placeholder: "Last Name",
    width: "half"
  },
  {
    name: "email",
    labelName: "Email",
    type: "email",
    isRequired: true,
    placeholder: "Email",
    validation: (value: unknown) => {
      const re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,24}/;

      if (!re.test(String(value))) {
        return {
          message: "invalid email"
        };
      } else {
        return undefined;
      }
    }
  },
  {
    name: "phone",
    labelName: "Phone",
    type: "tel",
    isRequired: true,
    placeholder: "Phone Number",
    onKeyDown: e => {
      const re = /[0-9\-+]+/g;

      if (
        !re.test(e.key) &&
        e.key.length === 1 &&
        !(e.ctrlKey && (e.key === "v" || e.key === "c"))
      ) {
        e.preventDefault();
      }
    }
  },
  {
    name: "password",
    labelName: "Password",
    type: "password",
    isRequired: true,
    placeholder: "Password"
  },
  {
    name: "password_confirmation",
    labelName: "Password Confirmation",
    type: "password",
    isRequired: true,
    placeholder: "Password Confirmation"
  },
  {
    name: "accepted_terms",
    labelName: "Accept Terms",
    type: "checkbox",
    description: <Terms />,
    isRequired: true
  }
];

export const userTypeFields = [
  {
    name: "entrepreneur",
    labelName: "Entrepreneur",
    type: "checkbox",
    description:
      "I may want to submit a profile of my company or pre-company, or I am simply curious."
  },
  {
    name: "investor",
    labelName: "Investor",
    type: "checkbox",
    description:
      "I want to discover startups seeking to gauge my interest in making an investment."
  },
  {
    name: "mentor",
    labelName: "Mentor",
    type: "checkbox",
    description:
      "I want to discover startups that may need my or my firm's expertise."
  },
  {
    name: "ip_custodian",
    labelName: "IP Custodian",
    type: "checkbox",
    description:
      "I may want to submit a profile of a technology transfer on behalf of the university or institute where I work."
  }
];
