import React, { useState } from 'react';
import { Logo } from "../../Logo";
import { Layout, Menu, Icon } from 'antd';
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import Logout from "../User/logout";

import { User } from "../../types";

import "./app-nav.less";

const { Sider } = Layout;

interface AppNavProps extends RouteComponentProps {
    user: User;
    navOpen: boolean;
    setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AppNav({location, user, navOpen, setNavOpen}: AppNavProps) {
    const selectedPage = location.pathname;

    return (
      <Layout className="side-menu" hasSider>
        <Sider
          theme="light"
          collapsible
          collapsed={!navOpen}
          onCollapse={(collapsed) => { setNavOpen(!collapsed)}}
        >
          <div className="main-logo">
            <Logo />
          </div>
          <Menu
            style={{
              height: "100%"
            }}
            theme="light"
            mode="inline"
            selectedKeys={[selectedPage]}
          >
            <Menu.Item key="/">
              <Link to="/">
                <Icon type="home" />
                <span>Home</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/dashboard">
              <Link to="/dashboard">
                <Icon type="dashboard" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/discover">
              <Link to="/discover">
                <Icon type="compass" />
                <span>Discover</span>
              </Link>
            </Menu.Item>
            {user ? (
              <Logout />
            ) : null}
          </Menu>
        </Sider>
      </Layout>
    );
}

export default withRouter(AppNav);