import React from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";


import StepForm from "../../StepForm";
import { personalInfoFields, userTypeFields } from "./fields";

import "./signup-form.less";

const CONFLICT = "CONFLICT";

const SIGNUP_MUTATION = gql`
  mutation createUser(
    $userInfo: UserSignupInput!
  ) {
    createUser(userInfo: $userInfo) {
      id
      email
      first_name
      last_name
    }
  }
`;

interface SignupFormProps {
    refetch: () => void
}

export default function SignupForm({ refetch }: SignupFormProps) {
    const history = useHistory();
    const [createUser, {loading}] = useMutation(SIGNUP_MUTATION);

    function hasSelectedUserType(formState) {
        return formState.investor ||
            formState.entrepreneur ||
            formState.ip_custodian ||
            formState.mentor;
    }

    const steps = [
        {
            name: "userTypes",
            title: "Select User Type",
            showNext: hasSelectedUserType,
            fields: userTypeFields,
            className: "user-types-form"
        },
        {
            name: "personalInfo",
            title: "Personal Info",
            fields: personalInfoFields
        }
    ];

    function onSubmit(formData) {
        const userInfo = fillInDefaults(formData);

        return createUser({ variables: { userInfo } }).then(() => {
            refetch();
            history.push("/dashboard")
        });
    }

    return (
        <div className="container">

            <StepForm
                steps = { steps }
                onSubmit = { onSubmit }
                className = "signup-form"
                errorsToCheck = { [CONFLICT] }
                loading={loading}
            />


        </div>
    )
}

function fillInDefaults(formData) {
    const filledData = Object.assign({}, formData);

    userTypeFields.forEach((utype) => filledData[utype.name] = !!filledData[utype.name]);

    return filledData;
}