import * as React from "react";
import { useQuery } from "@apollo/client";
import Spinner from "../Spinner"

interface QueryProps {
	query: any,
	variables?: any,
	children: any
}

export default function QueryLoader({query, variables, children}: QueryProps) {
	const {loading, error, data} = useQuery(query, {
		variables
	});

	if (loading) {
		return (
			<Spinner />
		)
	}

	if (error) {
		console.error(error);
		return (
			<div>
				<h4>Error...</h4>
				<span>{`${error}`}</span>
			</div>
		)
	}

	return (
		<React.Fragment>
			{_renderChildren(children, data)}
		</React.Fragment>
	)
}

function _renderChildren(children: any, data: any) {
	return React.Children.map(children, (child) => {
		const childWithProps = React.cloneElement(child, data);
		return (
			<React.Fragment>
				{childWithProps}
			</React.Fragment>
		);
	});
}
