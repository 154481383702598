import * as React from "react";
import {Link} from "react-router-dom";

export default function ProfileList(props) {
	const profiles = props.profiles || [];

	const profileItems = profiles.map((profile, index) => {
		return (
			<tr key={index}>
				<td>
					<Link to={`/${profile.type.replace("_", "-")}/${profile.slug}`}>{profile.name}</Link>
				</td>
				<td>
					{profile.type}
				</td>
				<td>
					{profile.published ? "yes" : "no"}
				</td>
				<td>
					{profile.created_at}
				</td>
			</tr>
		)
	});

	return (
		<table>
			<thead>
				<tr>
					<th>Name</th>
					<th>Type</th>
					<th>Published?</th>
					<th>Date Created</th>
				</tr>
			</thead>
			<tbody>
				{profileItems}
			</tbody>
		</table>
	)
}
