import React from "react";
import { Icon } from "antd"

interface LogoProps {
    width?: number;
}

export function Logo({width}: LogoProps) {
    const logoWidth = width || 64;

    return (
        <svg version="1.1" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 640" width={logoWidth} height={logoWidth}><defs><path d="M189.13 529L246.87 378.61L216.3 378.61L152.04 529L189.13 529Z" id="c1otjD0d8Q"></path><path d="M247.05 378.61L320 192.54L389.67 378.61L424.1 378.61L322.46 122.05L215.9 378.61L247.05 378.61Z" id="e4SN4B75c"></path><path d="M424.49 378.61L483.86 529L447.85 529L389.03 378.61L424.49 378.61Z" id="do0J68h4N"></path></defs><g><g><g><use  href="#c1otjD0d8Q" opacity="1" fill="#527088" fillOpacity="1"></use><g><use  href="#c1otjD0d8Q" opacity="1" fillOpacity="0" stroke="#527088" strokeWidth="1" strokeOpacity="1"></use></g></g><g><use  href="#e4SN4B75c" opacity="1" fill="#ffffff" fillOpacity="1"></use><g><use  href="#e4SN4B75c" opacity="1" fillOpacity="0" stroke="#527088" strokeWidth="1" strokeOpacity="1"></use></g></g > <g><use  href="#do0J68h4N" opacity="1" fill="#527088" fillOpacity="1"></use> <g><use  href="#do0J68h4N" opacity="1" fillOpacity="0" stroke="#527088" strokeWidth="1" strokeOpacity="1"></use></g ></g ></g ></g ></svg >
    )
}

const ARLogo = (props: any) => <Icon component={Logo} {...props} />

export default ARLogo;