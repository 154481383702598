import React from "react"
import classnames from "classnames";
import { Checkbox as AntdCheckbox } from "antd";

import "./checkbox.less"

interface CheckboxProps {
	checked: boolean,
	name: string,
	labelName?: string,
	description?: string | JSX.Element;
	onChange: (e:any) => void,
	className?: string;
}

export default function Checkbox({
	labelName,
	onChange,
	name,
	description,
	checked,
	className
}: CheckboxProps) {
	const label = labelName || name;

	function handleChange(e) {
		if (e.target.nodeName === "A") {
			return;
		}
		
		e.preventDefault();

		onChange({
			name,
			value: !checked
		})
	};

	const containerClass = classnames("checkbox", {
    checked: !!checked,
    [className as any]: !!className
  });

	return (
    <div id={name} className={containerClass} onClick={handleChange}>
      <AntdCheckbox
        onChange={e => {
          e.preventDefault();
        }}
        name={name}
        checked={checked}
      />
      <label htmlFor={name}>{label}:</label>
      <span className="checkbox__description">{description}</span>
    </div>
  );
}
