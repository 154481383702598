import React from "react";

export default function Terms() {
    return (
      <span>
        I am eighteen (18) years of age or older and I have read and agree to
        abide by AngelRoot’s{" "}
        <strong><a target="_blank" className="angelroot-link" href="/terms-of-service">
          Terms of Service
        </a></strong>{" "}
        and{" "}
        <strong><a
          target="_blank"
          rel="noopener noreferer"
          className="angelroot-link"
          href="/privacy-policy"
        >
          Privacy Policy
        </a></strong>
        .
      </span>
    );
}